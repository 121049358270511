.container {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  width: 200px;
  padding: 20px;
  border-right: 1px solid #ccc;
}

.canvas {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
}

.model {
  width: 200px;
  padding: 20px;
  border-left: 1px solid #ccc;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  background: #FFF7F4;
}

#c-left, #c-right {
  position: relative;
  display: inline-block;
  height: 50%;
  width: 50%;
}

#c-right {
  float: right;
}

#renderer {
  position: relative;
  height: 512px;
  width: 512px;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
 }
 
 #c-left {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 50%;
 }
 
 #c-right {
  position: relative;
  display: inline-block;
  float: right;
  height: 50%;
  width: 50%;
 }
 
 canvas {
  width: 100%;
  height: 100%;
 }
 .canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
}

canvas {
  border: 1px solid black; /* Optional: Add a border around the canvas */
}



.app {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100vh;
}

.side-panel {
  width: 30%; /* Adjust based on your preference */
  background-color: #f0f0f0;
  padding: 20px;
}

.fabric-canvas {
  width: 30%; /* Adjust based on your preference */
  background-color: #e81515;
  padding: 20px;
}

.three-d-model-display {
  width: 30%; /* Adjust based on your preference */
  background-color: #ddd;
  padding: 20px;
}
.fabric-canvas {
  width: 30%; /* Adjust based on your preference */
  background-color: #910e0e;
  padding: 20px;
}


.container {
  display: flex;
  flex-direction: row;
}

.fabric-canvas {
  width: 40%; /* Adjust based on your preference */
  background-color: #b8b3b3;
  padding: 20px;
}

.image-generation {
  width: 60%; /* Adjust based on your preference */
  padding: 20px;
}

#uploaded-image {
  filter: opacity(0.5) drop-shadow(0 0 0 blue);
}